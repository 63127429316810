<template>
  <BasePage :loading="loading">
    <v-container v-if="profile && !loading">
      <div>
        <ProfileTabs />
      </div>
    </v-container>
  </BasePage>
</template>

<script>
import ApiService from '@/services/api.service';
export default {
  components: {
    ProfileTabs: () => import('@/components/ProfileTabs'),
  },
  data() {
    return {
      loading: false,
      profile: null,
    };
  },
  methods: {
    async getPosts() {
      try {
        this.loading = true;
        const { data } = await ApiService.get('users/me');
        this.profile = data.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getPosts();
  },
};
</script>

<style></style>
